<template>
    <div class="popup">
        <div class="popup__container">
        <form class="popup__form" @submit.prevent="saveFolder">
            <h2 class="popup__title">{{$t('new_folder')}}</h2>
            <button class="button button--action" @click="close" type="button"><span class="button__icon icon icon-close"></span></button>
            <label class="popup__label">{{$t('folder_name')}}
            <input class="input" type="text" required v-model="name" :placeholder="$t('enter_folder_name')"/>
            </label>
            <p class="folder_path popup__label">{{$t('folder_location')}}</p>
            <div class="custom-select custom-select--multilevel">
            <vue-select :select_values="foldersNames"
              :_values="foldersIds"
              :actual_value="actualValue"
              @change_value="setNewValue"
            ></vue-select>
            </div>
            <button class="button button--primary" type="submit"><span class="button__text icon icon-folder">{{$t('create')}}</span>
            </button>
        </form>
        </div>
    </div>
</template>
<script>
import VueSelect from '../helpers/VueSelect.vue';
export default {
  components: { VueSelect },
  name: "FolderCreation",
  emits: ['save', 'close'],
  props: {
    subfolders: Array
  },
  data(){
    return {
      name: '',
      foldersNames: [],
      foldersIds: [],
      actualValue: '',
      flags: ['trash', "sent", "junk", "drafts"],
      actualParentId: 0
    }
  },
  methods: {
    close(){
      this.$emit('close')
    },
    saveFolder(){
      this.$emit('save', this.actualParentId, this.name)
    },
    setNewValue(val){
      const i = this.foldersIds.indexOf(val)
      if(i!==-1 && this.foldersNames[i]){
        this.actualValue = this.foldersNames[i]
        this.actualParentId = val
      }
    },
    getFolderName(folder){
      if(folder.name === "INBOX")
        return this.$t(folder.name)
      if(folder.system){
        const i = this.flags.findIndex(el => folder.system === el)
        if(i !== -1)
          return this.$t(this.flags[i])
      }
      const arr = folder.name.split('.') 
      if(arr.length > 0)
        return arr[arr.length-1]
      return folder.name
    }
  },
  created(){
    if(this.subfolders && this.subfolders.length > 0){
      this.subfolders.forEach(el => {
        if(el.id && el.name){
          let name = this.getFolderName(el)
          this.foldersNames.push(name)
          this.foldersIds.push(el.id)
        }       
      })
      this.actualValue = this.foldersNames[0]
      this.actualParentId = this.foldersIds[0]
    }
  }
}
</script>