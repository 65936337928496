let timer;
export default {
  async login(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "login",
    });
  },
  async signup(context, payload) {
    return context.dispatch("auth", {
      ...payload,
      mode: "signup",
    });
  },
  async auth(context, payload) {
    // const mode = payload.mode
    // const url = context.getters.url2 + payload.mode;
    let body = {
        username: payload.login,
        password: payload.password,
    };
    if(payload.mode === "signup"){
        body.firstname = payload.firstname,
        body.lastname = payload.lastname
    }
    // const response = await fetch(url, {
    //   method: "POST",
    //   headers: {
    //     "Content-Type": "application/json",
    //   },
    //   body: JSON.stringify(body),
    // });
    // const responseData = await response.json();
    // if (!response.ok) {
    //   const error = new Error(
    //     responseData.message || "Failed to authenticate. Check your login data", { cause: responseData }
    //   );
    //   throw error;
    // }
    // const expiresIn = +responseData.expiresIn * 1000;
    // const expirationDate = new Date().getTime() + expiresIn;
    // localStorage.setItem("token", responseData.token);
    // localStorage.setItem("login", responseData.login);
    // localStorage.setItem("tokenExpiration", expirationDate);

    // timer = setTimeout(function () {
    //   context.dispatch("autoLogout");
    // }, expiresIn);
  
    // context.commit("setUser", {
    //   token: responseData.token,
    //   login: responseData.login,
    // });
    // if(payload.mode === "signup"){
    //     context.commit("setUserName", {
    //         firstname: payload.firstname,
    //         lastname: payload.lastname
    //     });
    // }  
    // context.dispatch('getUserInfo');
  },
  // tryLogin(context) {
    // const token = localStorage.getItem("token");
    // const login = localStorage.getItem("login");
    // const tokenExpiration = localStorage.getItem("tokenExpiration");
    // const expiresIn = +tokenExpiration - new Date().getTime();
    // // console.log("expiresIn",expiresIn)
    // if (expiresIn < 0) {
    //   return;
    // }
    // timer = setTimeout(function () {
    //   context.dispatch("autoLogout");
    // }, expiresIn);
    // if (token && login) {
    //   context.commit("setUser", {
    //     token: token,
    //     login: login,
    //   });
    //     context.dispatch('getUserInfo');
    // }
  // },
  logout(context) {
    localStorage.removeItem("token");
    localStorage.removeItem("login");
    localStorage.removeItem("tokenExpiration");
    clearTimeout(timer);

    context.commit("setUser", {
      token: null,
      login: null,
    });
  },
  autoLogout(context) {
    context.dispatch("logout");
    context.commit("setAutoLogout");
  },
};
