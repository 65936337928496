import Split from 'split.js';
let split;
let sizes;
export const destroy_split = () => {
  if(split){
    split.destroy();
    split = null
  }
}
export const split_js = () => {
  let isResizable = false;
  sizes = localStorage.getItem('split-sizes')
  if (sizes) {
    sizes = JSON.parse(sizes)
  } else {
    sizes = [40, 60]
  }
  init()
  window.addEventListener("resize", function() {
    if ((window.matchMedia("(min-width: 992px)").matches) && !isResizable) {
      if(split){
        split.destroy();
        split = null
        init()
      }
    }
  });
}
function init(){
  const createGutter = () => {
    const gutter = document.createElement('div');
    gutter.className = `box__separator`;
    return gutter;
  }
  if ((window.matchMedia("(min-width: 992px)").matches)) {
    const box = document.getElementById('box_id')
    let max_size = 500
    if(box){
      max_size = box.offsetWidth - 575
    }
      split = Split(['#mails_id', '#mail'], {
          minSize: [370, 400],
          maxSize: [max_size],
          dragInterval: 1,
          gutter: createGutter,
          gutterSize: 8,
          sizes: sizes,
          onDragEnd: function (sizes) {
              // split_counter++
              // store.dispatch("splitCounter", split_counter)
              localStorage.setItem('split-sizes', JSON.stringify(sizes))
          },
      });
      // split.setSizes(sizes);
  }
}










