<template>
<div class="popup">
  <div class="popup__container">
    <form class="popup__form">
      <h2 class="popup__title">{{topic}}
      </h2>
      <p class="popup__text">{{text}}</p>
      <button class="button button--danger button--small" @click="confirm_dialog" type="button">
        <span class="button__text button__text--no-icon" v-if="!processing">{{ $t('yes') }}</span>
        <second-spinner v-else class="spinner_class"></second-spinner>
      </button>
      <button class="button button--secondary button--small" @click="cancel_dialog" type="button"
        :disabled="processing">
        <span class="button__text button__text--no-icon">{{ $t('cancel') }}</span>
      </button>
    </form>
  </div>
</div>
</template>
<script>
export default {
  name: 'ConfirmDialog',
  props: {
    topic: String,
    text: String,
    processing: {
      type: Boolean,
      default: false
    },
  },
  emits: ['confirm_dialog', 'cancel_dialog'],
  methods: {
    cancel_dialog(){
      this.$emit('cancel_dialog')
    },
    confirm_dialog(){
      this.$emit('confirm_dialog')
    }
  }
}
</script>