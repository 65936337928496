import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
    state(){
        return {
            login: null,
            token: null,
            didAutoLogout: false,
            firstname: null,
            lastname: null,
            url1: "/api/v1/",
            url2: "/auth/"
        }
    },
    mutations,
    actions,
    getters
}