<template>
<form class="search-line" :class="{'search-line--active': openSearch && isChecked, 'width_search': openSearch && hasChecked}"
  @click="openSearchLine" @submit.prevent="onClickSearch()" method="get" action="">
  <div class="search-line__wrapper icon icon-search">
    <input class="search-line__search" type="search" :placeholder="$t('enter_request')+'...'" v-model="search_text">
  </div>
  <button class="search-line__advanced icon icon-options" :class="{'search-line__advanced--active': advancedSearchButton || advancedSearchActive}" @click="toggleAdvancedSettings" type="button" data-bs-toggle="tooltip" :title="$t('additional_search')"></button>
  <button class="button button--secondary" type="submit"
    :class="{'mobile_search_btn': wrapper==='.aside-panel__container'}"><span class="button__text">{{$t('search_mails2')}}</span></button>
  <button class="search-line__close icon icon-close" type="button" @click.prevent.stop="close_search"></button>
</form>
<form class="advanced-search" :class="{'advanced-search--active': advancedSearchActive}" @submit.prevent="onClickSearch()" method="get" action="">
  <div class="advanced-search__container">
    <button class="advanced-search__close" @click="closeAdvancedSearch" type="button" data-bs-toggle="tooltip" :title="$t('close')"><span class="advanced-search__icon icon icon-close"></span></button>
    <fieldset class="advanced-search__fieldset">
      <div class="radio radio--group" style="margin-top: 24px;">
        <input class="radio__input" type="radio"
          :checked="radio_search_folder === '3'" 
          id="inbox-search-3" name="inbox-search" value="3" v-model='radio_search_folder'/>
        <label class="radio__label search_label"
          @click="radio_search_folder = '3'" 
          for="inbox-search-3">{{ $t('search_in_folder') }}</label>
        <input class="radio__input" type="radio"
          :checked="radio_search_folder === '1'"
         id="inbox-search-1" name="inbox-search" value="1" v-model='radio_search_folder'/>
        <label class="radio__label search_label" 
          @click="radio_search_folder = '1'" 
          for="inbox-search-1">{{ $t('search_in_all_folders') }}</label>
      </div>
    </fieldset>
    <fieldset class="advanced-search__fieldset">
      <legend class="advanced-search__legend">{{ $t('where_to_look') }}</legend>
      <div class="checkbox checkbox--bordered"
          :class="{'checkbox--disabled': ch_subject && !ch_text && !ch_from}">
        <input class="checkbox__input" type="checkbox" id="aside-panel-advanced-search-mail-title" :checked="ch_subject"/>
        <label class="checkbox__label icon icon-check" for="aside-panel-advanced-search-mail-title"
          :disabled="true"
          @click.prevent="subj_f">{{ $t('subject1') }}</label>
      </div>
      <div class="checkbox checkbox--bordered"  :class="{'checkbox--disabled': !ch_subject && ch_text && !ch_from}">
        <input class="checkbox__input" type="checkbox" id="aside-panel-advanced-search-mail-body" :checked="ch_text"
         />
        <label class="checkbox__label icon icon-check" for="aside-panel-advanced-search-mail-body"
          @click.prevent="text_f">{{ $t('letter_body') }}</label>
      </div>
      <div class="checkbox checkbox--bordered"
          :class="{'checkbox--disabled': !ch_subject && !ch_text && ch_from}">
        <input class="checkbox__input" type="checkbox" id="aside-panel-advanced-search-sender" :checked="ch_from"/>
        <label class="checkbox__label icon icon-check" for="aside-panel-advanced-search-sender"
          @click.prevent="from_f">{{ $t('sender') }}</label>
      </div>
    </fieldset>
      <fieldset class="advanced-search__fieldset">
      <legend class="advanced-search__legend advanced-search__legend--hidden">{{$t('date')}}</legend>
      <datepicker-component
        :dpname="dpname_start"
        :isDisabled="radio_search_folder === '1'"
        :inputPlaceholder="$t('date_from')"
        :wrapper="wrapper"
        :startDate="startDate_from"
        @set_clear_function="set_clear_function_start"
        @set_date="set_date_start">
        {{$t('date_from')}}
      </datepicker-component>
      <datepicker-component
        :dpname="dpname_end"
        :isDisabled="radio_search_folder === '1'"
        :inputPlaceholder="$t('date_to')"
        :wrapper="wrapper"
        :startDate="startDate_to"
        @set_clear_function="set_clear_function_end"
        @set_date="set_date_end">
        {{$t('date_to')}}
      </datepicker-component>
    </fieldset>
    <button class="button button--danger button--small" type="button" id="clear-search-button" @click="clearAdvancedOptions"><span class="button__text icon icon-trash">{{$t('clear')}}</span>
    </button>
    <button class="button button--primary button--small" style="display: block;" type="submit"><span class="button__text icon icon-search">{{$t('search_mails')}}</span>
    </button>    
  </div>
</form>
</template>
<script>
import {getMyDate7, getMyDate3} from '../helpers/Date.js'
import DatepickerComponent from './DatepickerComponent.vue'
export default {
  name: 'SearchLine',
  emits: ['search_mail', 'refresh', 'active_search'],
  components: {DatepickerComponent},
  props: {
    email: String,
    activeEmail: Number,
    subfolders: Array,
    amount: Number,
    counter_click_folder: Number,
    lang: String,
    wrapper: String,
    hasChecked: Boolean
  },
  data(){
    return {
      ch_text: true,
      ch_from: true,
      ch_subject: true,
      search_alias: '',
      aliasesEmails: [],
      aliasesId: [],
      sender: '',
      receiver: '',
      advancedSearchActive: false,
      advancedSearchLineActive: false,
      search_text: '',
      openSearch: false,
      page: 1,
      ch_folder: false,
      aliases: [],
      ch_important: false,
      radio_search_folder: '3',
      date_to: "",
      date_from: "",
      startDate_to: "",
      startDate_from: "",
      isActive: false,
      isChecked: true,
      dpname_start: "dp_search_start",
      dpname_end: "dp_search_end",
    }
  },
  dpStartInterface: {
    clear: () => {}
  },
  dpEndInterface: {
    clear: () => {}
  },
  watch: {
    hasChecked(val){
      if(this.openSearch && val)
        this.isChecked = !val
    },
    counter_click_folder(){
      this.$store.dispatch('setIsSearch', false)
      this.isChecked = true
      this.advancedSearchActive = false
      this.advancedSearchLineActive = false
      this.openSearch = false
      this.search_text = ''
      this.page = 1
      this.isActive = false
      this.$emit('active_search', false)
      this.clearAdvancedOptions()
    },
    getSearchPagination(val, old){
      if(val.type !== old.type || val.counter !== old.counter)
        this.searchMails(val.type)
    },
    radio_search_folder(val){
      if(val === '1')
        this.clearDP()
    },
    '$route' (to, from){
      if(from.params.a_id !== to.params.a_id)
        return
      if( to.name !== 'search' &&  to.name !== 'search_message' ){
        if(from.name === 'search' ||  from.name === 'search_message')
          this.close_search_line(this.activeEmail === +from.params.f_id)
        return
      }
      let pathBefore = '', pathAfter = ''
      pathBefore = this.getFullPath(from.name, from.fullPath)
      pathAfter = this.getFullPath(to.name, to.fullPath)
      if( pathBefore !== pathAfter )
        this.changeSearchRequest()
    },
  },
  computed: {
    advancedSearchButton(){
      if(this.radio_search_folder === '3' && this.ch_subject && this.ch_text && this.ch_from && this.date_from === '' && this.date_to === '')
        return false
      return true
    },
    getSearchPagination(){
      return this.$store.getters['getSearchPagination'] 
    }
  },
  methods: {
    getFullPath(name, fullPath){
      if(name === 'search')
        return fullPath
      let arr = fullPath.split('/')
      arr.pop()
      return arr.join('/')
    },
    set_clear_function_start(childInterface){
      this.$options.dpStartInterface = childInterface
    },
    set_clear_function_end(childInterface){
      this.$options.dpEndInterface = childInterface
    },
    clearDP(){
      this.$options.dpStartInterface.clear();
      this.$options.dpEndInterface.clear();
    },
    set_date_start(formattedDate){
      this.date_from = getMyDate3(formattedDate)
    },
    set_date_end(formattedDate){
      this.date_to = getMyDate7(formattedDate)
    },
    subj_f(){
      if(this.ch_subject && !this.ch_text && !this.ch_from)
        return
      this.ch_subject = !this.ch_subject
    },
    text_f(){
      if(!this.ch_subject && this.ch_text && !this.ch_from)
        return
      this.ch_text = !this.ch_text
    },
    from_f(){
      if(!this.ch_subject && !this.ch_text && this.ch_from)
        return
      this.ch_from = !this.ch_from
    },
    changeImportant(){
      this.ch_important = !this.ch_important
      if(this.ch_important)
        this.ch_folder = false
    },
    changeFolder(){
      this.ch_folder = !this.ch_folder
      if(this.ch_folder){
        this.ch_important = false
        this.clearDP()
      }
    },
    clearAdvancedOptions(){
      this.date_to = ""
      this.date_from = ""
      this.sender = ""
      this.receiver = ""
      this.search_alias = this.aliasesEmails[0]
      this.clearDP()
      this.ch_folder = false
      this.ch_text = true
      this.ch_from = true
      this.ch_subject = true
      this.radio_search_folder = '3'
    },
    closeAdvancedSearch(){
      this.advancedSearchActive = false
      this.advancedSearchLineActive = false
      this.clearAdvancedOptions()
    },
    async searchMails(str){
      // ||  this.radio_search_folder === '3' && this.search_text.trim() === '' && this.date_to === "" && this.date_from === ""
      if(this.radio_search_folder === '1' && this.search_text.trim() === '')
        return
      let values = {}
      if(str && str === 'next')
        this.page++
      else if (str && str === 'previous')
        this.page--
      else if(str && str === 'first')
        this.page = 1
      values = {
        request_type: "get",
        request: {
          page: this.page,
          limit: this.amount || 20
        },
      }
      const f_id = this.$route.params.f_id 
      const l_id = this.$route.params.l_id 
      if(this.radio_search_folder !== "1"){
        if(f_id && f_id !== 'false')
          values.request['folder_id'] = +f_id
        else if(l_id && l_id !== 'false')
          values.request['label_id'] = +l_id
      } else{
        this.date_from = ""
        this.date_to = ""
      }
      if(this.ch_from)
        values.request['from'] = this.search_text
      if(this.ch_subject)
        values.request['subject'] = this.search_text
      if(this.ch_text)
        values.request['text'] = this.search_text
      if(!this.ch_folder && (this.date_from || this.date_to)){
        values.request['date'] = {}
        if(this.date_from)
          values.request['date']['since']=this.date_from
        if(this.date_to)
          values.request['date']['before']=this.date_to
      }
      const em = await this.$store.getters['getLeftSidebarSelect']
      if(em && em !== this.$t('all_messages')){
        const i = this.aliases.findIndex(el => el.email === em)
        if(i!==-1)
          values.request['alias_id'] = this.aliases[i].id 
        else if(em === this.email)
          values.request['alias_id'] = 0
      }
      this.$emit("search_mail", values, str)
      this.advancedSearchActive = false
      this.$store.dispatch('setIsSearch', true)
      this.isActive = true
      this.$emit('active_search', true)
    },
    onClickSearch(){
      const f_id = this.$route.params.f_id 
      const l_id = this.$route.params.l_id 
      if(this.radio_search_folder === "1"){
        this.date_from = ""
        this.date_to = ""
      }
      this.$router.push({name: 'search', params: {
        a_id: this.$route.params.a_id, 
        f_id: f_id ? ( f_id === 'false' ? 'false' : f_id ) : 'false',
        l_id: l_id ? ( l_id === 'false' ? 'false' : l_id ) : 'false',
        page: this.page,
        search_text: this.search_text || "false",
        all_folder: this.radio_search_folder === '1',
        subject: this.ch_subject,
        text: this.ch_text,
        sender: this.ch_from,
        from: this.date_from || 'false',
        to: this.date_to || 'false'
      }})
    },
    close_search(){
      const route_name = this.$route.name
      if(route_name !== 'search' && route_name !== 'search_name'){
        this.advancedSearchActive = false
        this.advancedSearchLineActive = false
        this.openSearch = false
      }
      if(this.activeEmail !== -1)
        this.$router.push({name: 'folder', params: {a_id: this.$route.params.a_id, f_id: this.activeEmail}})
    },
    close_search_line(refresh){
      this.isChecked = true
          this.advancedSearchActive = false
      this.advancedSearchLineActive = false
      this.openSearch = false
      this.isActive = false
      this.$emit('active_search', false)
      this.search_text = ''
      this.page = 1
      this.clearAdvancedOptions()
      const isSearch = this.$store.getters['getSearch']
      if(isSearch){
        this.$store.dispatch('setIsSearch', false)
        if(!refresh)
          this.$emit('refresh')
      }
    },
    openSearchLine(){
      this.openSearch = true
      this.isChecked = true
    },
    toggleAdvancedSettings(){
      this.advancedSearchActive = !this.advancedSearchActive
      this.advancedSearchLineActive = !this.advancedSearchLineActive
    },
    changeSearchRequest(){
      const route_name = this.$route.name
      const params = this.$route.params
      if(route_name == 'search' || route_name == 'search_message'){
        this.radio_search_folder = params?.all_folder === 'true' ? '1' : '3'
        this.ch_subject = params?.subject === 'true'
        this.ch_text = params?.text === 'true'
        this.ch_from = params?.sender === 'true'
        this.date_from = params?.from === 'false' ? "" : params?.from
        this.date_to = params?.to === 'false' ? "" : params?.to
        this.startDate_from = this.date_from || ""
        this.startDate_to = this.date_to || ""
        this.page = +params?.page
        if(params?.search_text){
          if(params.search_text === 'false')
            this.search_text = ""
          else{
            try {
              this.search_text = decodeURI(params.search_text);
            } catch (e) {
              console.error(e);
            }
          }
        }
        this.openSearchLine()
        this.searchMails()
      }
    }
  },
  created(){
    this.changeSearchRequest()
    window.addEventListener('click', (e) => {
      if(this.openSearch) {
        if((!e.target.closest('.search-line')) && (!e.target.closest('.advanced-search__close')) && (!this.advancedSearchActive)) {
          const isSearch = this.$store.getters['getSearch']
            if(this.search_text.trim()===''){
              this.openSearch = false
              this.clearAdvancedOptions()
              if(isSearch){
                this.$emit('refresh')
                this.$store.dispatch('setIsSearch', false)
              }
            } else if(isSearch)
              this.openSearch = false
        }
        if(!e.target.closest('.advanced-search__button--other') && (!e.target.closest('.advanced-search__dropdown'))) {
          this.advancedSearchOtherActive = false
        }
      }
    });
    let that = this;
    document.addEventListener('keyup', function (evt) {
      if(evt.keyCode === 27 && that.openSearch)
        that.close_search_line();
    });
    this.aliases = []
    const account = this.$store.getters["getAccount"]
    if(account?.aliases)
      this.aliases = [...account.aliases]
    if(account?.email)
      this.aliases.push({email: account.email, id: 0})
  },
}
</script>
<style scoped>
.mobile_search_btn{
  padding: 6px 3px;
  width: 54px;
}
.search_label{
  margin-top: 12px;
}
</style>