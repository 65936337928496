import {errors_en} from './errors/errors_en.js'
import {errors_ru} from './errors/errors_ru.js'
import {domains_en} from './domains/domains_en'
import {domains_ru} from './domains/domains_ru'
import {text_en} from './text/text_en'
import {text_ru} from './text/text_ru'
export const messages = {
    "en": {
        ...text_en,
        ...errors_en,
        ...domains_en
    },
    "ru": {
        ...text_ru,
        ...errors_ru,
        ...domains_ru
    }
}