<template>
  <label class="advanced-search__label" :for="dpname"><slot></slot>
    <!-- advanced-search__datepicker--start -->
    <div class="datepicker__wrapper advanced-search__datepicker" :class="dpname">
      <input :disabled="isDisabled" class="datepicker advanced-search__datepicker-start advanced-search__input" :placeholder="inputPlaceholder" type="text" :id="dpname" autocomplete="off">
    </div>
  </label>
</template>
<script>
import localeEn from 'air-datepicker/locale/en';
import localeRu from 'air-datepicker/locale/ru';
import {popperDatepicker} from '../helpers/datapicker.js'
import AirDatepicker from 'air-datepicker';
export default{
  emits: ['set_date', 'set_clear_function'],
  props: {
    lang: String,
    isDisabled: Boolean,
    inputPlaceholder: String,
    dpname: String,
    wrapper: String,
    startDate: String
  },
  data(){
    return {
      dp: null
    }
  },
  watch: {
    startDate(val){
      if(val)
        this.dp.selectDate(new Date(val))
      else 
        this.dp.clear()
    }
  },
  methods: {
    initDatapicker(){
      const that = this
      let lang = this.$store.getters['getLanguage']
      if(!lang)
        lang = this.lang
      const locale = lang === 'ru' ? localeRu : localeEn
      let startDate = "" 
      if(this.startDate)
        startDate = new Date(this.startDate)
      this.dp = new AirDatepicker(`${this.wrapper} .${this.dpname} .advanced-search__input`, {
          selectedDates: startDate ? [startDate] : [],
          locale: locale,
          inline: false,
          dateFormat: 'dd.MM.yyyy',
          onSelect({...dates}){
              if(dates?.formattedDate){ 
                that.$emit('set_date', dates.formattedDate)
              }
          },
          buttons: ['clear'] ,
          position({
              $datepicker,
              $target,
              $pointer,
          }) {
              popperDatepicker($target, $datepicker, $pointer)
          }  
      });
    },
    clearDP(){
      this.dp.clear()
    }
  },
  mounted(){
    this.initDatapicker()
    this.$emit("set_clear_function", {
      clear: () => this.clearDP()
    })
  }
}  
</script>