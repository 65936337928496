export default {
    login(state){
        return state.login
    },
    token(state){
        return state.token
    },
    didAutoLogout(state){
        return state.didAutoLogout
    },
    url1(state){
        return state.url1
    },
    url2(state){
        return state.url2
    }
}