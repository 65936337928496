<template>
  <p class="avatar" 
      :style="{backgroundColor: stringToColor}" >
      <strong class="avatar__letter">{{avatar_letter}}</strong>
  </p>
</template>
<script>
export default {
  name: "MailAvatar",
  emits:[],
  props: {
    name: String,
    email: String
  },
  computed: {
    stringToColor(){
      let hash = 0;
      let color = '#';
      let i;
      let value;
      let stringLength;
      let str
      if(this.name)
        str = this.name
      else if(this.email)
        str = this.email
      if(!str)
        return color + '333333';
      stringLength = str.length;
      for (i = 0; i < stringLength; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      for (i = 0; i < 3; i++) {
        value = (hash >> (i * 8)) & 0xFF;
        color += ('00' + value.toString(16)).substr(-2);
      }
      return color;
    },
    avatar_letter(){
      const str = this.name
      const arr = str.split(" ")
      if(arr && arr.length > 1){
        const res1 = arr[0].match(/[a-zA-Zа-яА-Я0-9]/gi)
        const res2 = arr[1].match(/[a-zA-Zа-яА-Я0-9]/gi)
        if(res1 && res2 && res1.length > 0 && res2.length > 0)
          return (res1[0]+res2[0]).toUpperCase()
        if(res1 && res1.length > 1)
          return (res1[0]+res1[1]).toUpperCase()
        if(res2 && res2.length > 1)
          return (res2[0]+res2[1]).toUpperCase()
      } else if(arr && arr.length === 1){
        const res1 = arr[0].match(/[a-zA-Zа-яА-Я0-9]/gi)
        if(res1 && res1.length > 1)
          return (res1[0]+res1[1]).toUpperCase()
      }
      const str2 = this.email
      const res = str2.match(/[a-zA-Zа-яА-Я0-9]/gi)
      if(res && res.length > 1)
        return (res[0]+res[1]).toUpperCase()
      return "NN"
    },
  }
}
</script>