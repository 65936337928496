export const getMyDate = (lang, str) => {
    const date = new Date(str);
    const current_year = (new Date()).getFullYear()
    const year = date.getFullYear()
    let results = ''
    try{
      let options = {}
      if(year < current_year){
        options = {hour: "2-digit", minute:"2-digit", second:"2-digit", day: '2-digit', month: 'short', year: 'numeric' }
      }else{
        options = {hour: "2-digit", minute:"2-digit", second:"2-digit", day: '2-digit', month: 'short' }
      }
      results = date.toLocaleString(lang, options);
    }catch(e){
      results = date.toUTCString().split(" ");
      return results[4] + " " + results[1] + " " + results[2];
    }
    return results
}

export const getMyDate2 = (lang, str) => {
  const date = new Date(str);
  const current_year = (new Date()).getFullYear()
  const year = date.getFullYear()
  let results = ''
  try{
    let options = {}
    if(year < current_year){
      options = {hour: "2-digit", minute:"2-digit", day: '2-digit', month: 'short', year: 'numeric' }
    }else{
      options = {hour: "2-digit", minute:"2-digit", day: '2-digit', month: 'short' }
    }
    results = date.toLocaleString(lang, options);
  }catch(e){
    results = date.toUTCString().split(" ");
    return results[4] + " " + results[1] + " " + results[2];
  }
  return results
}
export const getMyDate3 = (str) => {
  let result = []
  result = str.split('.') 
  if(result.length === 3){
    return result[2]+'-'+result[1]+'-'+result[0]+"T00:00:00Z"
  }
  return str
}
export const getMyDate4 = (str) => {
  let result = []
  result = str.split('T') 
  if(result.length > 1){
    let arr = result[0].split('-')
    if(arr.length > 2){
      return [arr[0], +arr[1]-1, arr[2]]
    }
  }
  return str
}
export const getMyDate5 = (lang, str) => {
  const date = new Date(str);
  const current_year = (new Date()).getFullYear()
  const year = date.getFullYear()
  let results = ''
  try{
    let options = {}
    if(year < current_year){
      options = {hour: "2-digit", minute:"2-digit", day: '2-digit', month: '2-digit', year: 'numeric' }
    }else{
      options = {hour: "2-digit", minute:"2-digit", day: '2-digit', month: '2-digit' }
    }
    results = date.toLocaleString(lang, options);
    results = results.replace(/\//g, '.')
  }catch(e){
    results = date.toUTCString().split(" ");
    return results[4] + " " + results[1] + " " + results[2];
  }
  return results
}
export const getMyDate6 = (str) => {
  const date = new Date(str);
  let results = ''
  try{
    let options = {hour: "2-digit", minute:"2-digit", second:"2-digit", day: '2-digit', month: '2-digit', year: 'numeric' }
    results = date.toLocaleString('ru', options);
    results = results.replace(/\./g, '-').replace(/,/g, '')
  }catch(e){
    results = date.toUTCString().split(" ");
    return results[4] + " " + results[1] + " " + results[2];
  }
  return results
}
export const getMyDate7 = (str) => {
  let result = []
  result = str.split('.') 
  if(result.length === 3){
    return result[2]+'-'+result[1]+'-'+result[0]+"T23:59:59Z"
  }
  return str
}
export const getMyDate8 = (lang, str) => {
  const date = new Date(str);
  const current_year = (new Date()).getFullYear()
  const year = date.getFullYear()
  const date2 = new Date(str)
  const current_date = new Date()
  date2.setHours(0,0,0,0)
  current_date.setHours(0,0,0,0)
  let results = ''
  try{
    let options = {}
    if(year < current_year){
      options = {day: '2-digit', month: '2-digit', year: 'numeric' }
    }else{
      if(date2 < current_date)
        options = {day: '2-digit', month: 'short'}
      else
        options = {hour: "2-digit", minute:"2-digit"}
    }
    results = date.toLocaleString(lang, options);
  }catch(e){
    results = date.toUTCString().split(" ");
    return results[4] + " " + results[1] + " " + results[2];
  }
  return results
}