import mutations from './mutations.js'
import actions from './actions.js'
import getters from './getters.js'

export default {
    state(){
        return {
            user_info: {},
            contacts: {contacts: []},
            mails: [],
            total: 0,
            all: 0,
            folders: [],
            labels: [],
            user: {},
            opened_mail: {},
            account: {},
            left_sidebar_select: '',
            settings: {},
            controller: new AbortController(),
            language: '',
            filters: [],
            connection: null,
            groups: [],
            isSearch: false,
            searchPagination: {
              counter: 0,
              type: ''
            },
            ba: null
        }
    },
    mutations,
    actions,
    getters
}
