export const copy_func = (obj, text, icon) => {
  let range = document.createRange();
  let text_node = document.createTextNode(text);
  document.body.appendChild(text_node);
  range.selectNode(text_node);
  window.getSelection().removeAllRanges();
  window.getSelection().addRange(range);
  try{
    document.execCommand('copy');
    obj[icon] = true;
    setTimeout(() => {
      obj[icon] = false;
      return;
    }, 1500);
  }
  catch(err){
    console.log('Oops, unable to copy');
  }
  document.body.removeChild(text_node);
  window.getSelection().removeAllRanges()
}