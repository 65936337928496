<template>
  <li class="inner__item inner__item--folder " v-if="folder.subfolders && folder.subfolders.length > 0">
    <button class="inner__folder  icon icon-folder dropzone1 "
    :class="{'inner__folder--active': active===folder.id}" type="button" 
    @drop="onDrop($event, folder.id)"
    @dragover.prevent
    @click="openFolder(folder.name, folder.id, getFolderName(folder.name), folder.unread, true)">
      <span class="inner__name">
        {{getFolderName(folder.name)}}
      </span>
      <span class="inner__counter" v-if="folder.unread > 0">+{{folder.unread}}</span>
      <span class="inner__icon icon icon-arrow-dropdown" data-bs-toggle="collapse" :data-bs-target="'#inner-folder-'+folder.id" aria-expanded="true"></span>
    </button>
    <div class="inner__folder-content show" :id="'inner-folder-'+folder.id">
      <ul class="inner__list">
        <!-- <folder-node v-for="subf in folder.subfolders" :key="subf.name" :folder="subf"></folder-node> -->
        <li class="inner__sub-item inner__sub-item--folder dropzone1" v-for="subf in subfolders" :key="subf.name" 
          @drop="onDrop($event, subf.id)"
          @dragover.prevent>                
            <button class="inner__folder icon icon-folder"
              :class="{'inner__folder--active': active===subf.id}" type="button" 
              @click="openFolder(subf.name, subf.id, getFolderName(subf.name), subf.unread, true)">
              <span class="inner__name">
                {{getFolderName(subf.name)}}
              </span>
              <span class="inner__counter" v-if="subf.unread > 0">+{{subf.unread}}</span>
            </button>
        </li>
      </ul>
    </div>
  </li>
  <li v-else class="inner__item inner__item--folder dropzone1"  @click="openFolder(folder.name, folder.id, getFolderName(folder.name), folder.unread, true)"
  @drop="onDrop($event, folder.id)"
  @dragover.prevent>
    <button class="inner__folder icon icon-folder"
      :class="{'inner__folder--active': active===folder.id}" type="button">
      <span class="inner__name">{{getFolderName(folder.name)}}</span>
      <span class="inner__counter" v-if="folder.unread > 0">+{{folder.unread}}</span>
      </button>
  </li>
</template>
<script>
export default {
  name: "folderNode",
  props: {
    folder: Object,
    active: Number,
  },
  emits: ['move_to', 'open_folder'],
  data(){
    return {
      subfolders: [],
      flags: ['trash', "sent", "junk", "drafts"],
      userAlias: ''
    }
  },
  computed:{
  },
  methods: {
    getFolderName(name){
      const arr = name.split('.') 
      if(arr.length > 0)
        return arr[arr.length-1]
      return name
    },
    openFolder(folder_name, id, name, unread, isMobile){
      this.$emit('open_folder', folder_name, id, name, unread, isMobile)
    },
    getSubFolders(folder){
      if(folder.subfolders){
        this.subfolders = this.subfolders.concat(folder.subfolders);
        folder.subfolders.forEach(el => {
          this.getSubFolders(el)
        })
      }
    },
    onDrop(evt, id) {
      const uid = evt.dataTransfer.getData('itemID')
      this.$emit("move_to", id, +uid);
      if (evt.target.classList.contains("dropzone1"))
        evt.target.classList.remove("dragover");
    },
  },
  created(){
    this.userAlias = this.$store.getters['user'].Info?.alias
    this.getSubFolders(this.folder)
  }
};
</script>
<style scoped>
  .dropzone1.dragover {
    border: 1px dashed gray;
  }
</style>






