<template>
    <teleport to="body">
        <!-- <div v-if="show" @click="tryClose" class="backdrop"></div> -->
        <transition name="dialog">
            <!-- <dialog open v-if="show">
                <header>
                    <slot name="header">
                        <h4>{{title}}</h4>
                    </slot>
                </header>
                <section>
                    <slot>
                    </slot>
                </section>
                <menu v-if="!fixed">
                    <slot name="actions">
                        <button @click="tryClose" class="close_class">OK</button>
                    </slot>
                </menu>
            </dialog> -->
<!-- notification_error -->
            <div class="notification" v-if="show"
            :class="{'notification_error': isError}">
              <div class="notification__timer"> 
                <div class="notification__timer-bar" :style="{width:widthProgressBar+'%'}"> </div>
              </div>
              <div class="notification__row" style="padding: 12px 10px;"> <span class="notification__icon notification__icon_error icon icon-warning"></span><span class="notification__icon icon icon-bell"></span>
                <!-- <h4 class="notification__title" @click="tryClose">{{title}}</h4> -->
                <!-- <button class="notification__close" @click="tryClose"> <img src="images/icons/Close-without-bg.svg"></button> -->
                <slot></slot>
                
              </div>
              <!-- <div class="notification__text">
                  <slot></slot>
                </div> -->
            </div>
        </transition>        
    </teleport>
</template>
<script>
export default {
    emits: ['close'],
    props: {
        widthProgressBar: {
            type: Number,
            default: 40
        },
        show: {
            type: Boolean,
            required: true
        },
        isError: {
            type: Boolean,
            default: true,
            // required: true
        },
        title: {
            title: String,
            // required: true
        },
        fixed: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    methods: {
        tryClose(){
            if(this.fixed){
                return
            }
            this.$emit("close")
        }
    }
}
</script>
<style scoped>
.notification__text{
    min-height: 30px;
}
.notification{
    z-index: 1001;
}
.backdrop{
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: rgba(0,0,0,0.75);
    z-index: 100;
}
dialog{
    position: fixed;
    /* top: 20vh;
    left: 10%;
    width: 80%; */
    right: 10vh;
    bottom: 10vh;    
    width: 300px;
    left: unset;


    z-index: 123123;
    /* z-index: 102; */
    border-radius: 12px;
    border: none;
    box-shadow: 0 2px 8px rgba(0,0,0,0.26);
    padding: 0;
    margin: 0;
    overflow: hidden;
    background-color: white;
}
header{
    background-color: #0080d8;
    color: white;
    width: 100%;
    /* padding: 1rem; */
    padding: 0.5rem 1rem;
}
header h2{
    margin: 0;
}
section {
    /* padding: 1rem; */
    padding: 0.5rem 1rem;
}
menu{
    /* padding: 1rem; */
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: flex-end;
    margin: 0;
}
.dialog-enter-from, .dialog-leave-to{
    opacity: 0;
    transform: scale(0.8);
}
.dialog-enter-active{
    transition: all 0.3s ease-out;
}
.dialog-leave-active{
    transition: all 0.3s ease-in;
}
.dialog-enter-to, .dialog-leave-from{
    opacity: 1;
    transform: scale(1);
}
/* @media (min-width: 768px){
    dialog{ */
        /* left: calc(50% - 20rem);
        width: 40rem; */
    /* }
} */
.close_class{    
    padding: 6px 15px;
    border-radius: 5px;
    border: 1px solid gray;
    background: aliceblue;
    cursor: pointer;
}
</style>
<style>
.notification__icon.icon-bell + p{
  margin-left: 25px;
}
</style>