// import AirDatepicker from 'air-datepicker';
import { createPopper } from '@popperjs/core';

export const popperDatepicker = (target, datepicker, pointer) => {
    let popper = createPopper(target, datepicker, {
        placement: 'top',
        modifiers: [{
                name: 'flip',
                options: {
                    padding: {
                        top: 64
                    }
                }
            },
            {
                name: 'offset',
                options: {
                    offset: [0, 20]
                }
            },
            {
                name: 'arrow',
                options: {
                    element: pointer
                }
            }
        ]
    })
    return function completeHide() {
        popper.destroy(); 
        // done();
    }
}
// export const datapickerInit = () => {
    // new AirDatepicker('.header .advanced-search__datepicker--start .advanced-search__input', {
    //     inline: false,
    //     position: 'bottom center',
    //     position({
    //         $datepicker,
    //         $target,
    //         $pointer,
    //         done
    //     }) {
    //         popperDatepicker($target, $datepicker, $pointer)
    //     }  
    // });
    // new AirDatepicker('.header .advanced-search__datepicker--end .advanced-search__input', {
    //     inline: false,
    //     position: 'bottom center',
        
    //     position({
    //         $datepicker,
    //         $target,
    //         $pointer,
    //         done
    //     }) {
    //         popperDatepicker($target, $datepicker, $pointer)
    //     }
    // });
// }