export default {
    setUser(state, payload){
        state.token = payload.token
        state.login = payload.login
        state.didAutoLogout = false
    },
    setAutoLogout(state){
        state.didAutoLogout = true
    },
    setUserName(state, payload){
        state.firstname = payload.firstname
        state.lastname = payload.lastname
    },
}