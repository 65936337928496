export const strip_tags = (input, allowed) => {
    allowed = (((allowed || "") + "").toLowerCase().match(/<[a-z][a-z0-9]*>/g) || []).join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    var tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
        commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input.replace(commentsAndPhpTags, '').replace(tags, function ($0, $1) {
        return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
    });
}
export const tags = () => {
    class Tags {
        //конструктор
        constructor(state) {
            this.state = {
                selector: '',
                max: null,
                duplicate: false,
                wrapperClass: '',
                tagClass: 'tag',
                parentNode: '',
                ...state
            }

            this.arr = [];
            this.input = document.createElement('input');
            this.wrapper = document.createElement('div');
            this.original_input = this.state.parentNode.querySelector(this.state.selector);
            // this.isEdit = false;
            this.buildUI();
            this.addEvents();
        }

        //создание редактируемой области для введения электронных почт
        buildUI() {
            this.input.setAttribute('type', 'text');
            this.input.setAttribute('class', 'tag__input');

            this.wrapper.append(this.input);
            this.wrapper.classList.add(this.state.wrapperClass);
            this.original_input.setAttribute('hidden', 'true');
            this.original_input.parentNode.insertBefore(this.wrapper, this.original_input);
        }

        //создание событий для input
        // addEvents() {
            // this.wrapper.addEventListener('click', () => {
            //     this.input.focus();
            // });

            // this.input.addEventListener('keydown', (event) => {
            //     const string = this.input.value.trim();

            //     if (!!(~[9, 13, 188].indexOf(event.keyCode))) {
            //         this.input.value = "";
            //         if (string != "") {
            //             this.addTag(string);
            //         }
            //     }
            // });

            // document.addEventListener('click', (event) => {
            //     if(!event.target === this.original_input) {

            //     }
            // })
        // }

        //добавление тега на основании введенной строки
        addTag(string) {
            if (this.anyErrors(string)) return;

            this.arr.push(string);
            const tag = document.createElement('div');
            const tagIcon = document.createElement('span');
            const tagName = document.createElement('span');
            const closeTagButton = document.createElement('span');

            tagIcon.setAttribute('class', 'tag__icon');
            tagIcon.classList.add('icon', 'icon-contacts');
            tagName.setAttribute('class', 'tag__name');
            closeTagButton.setAttribute('class', 'tag__close');
            closeTagButton.classList.add('icon', 'icon-close');

            tag.className = this.state.tagClass;
            tag.appendChild(tagIcon);
            tag.appendChild(tagName);
            tag.appendChild(closeTagButton);
            tagName.textContent = string;

            // tag.addEventListener('click', event => {
            //     // event.preventDefault();
            //     this.openEditField(tag);
            // });

            // closeTagButton.addEventListener('click', event => {
                // event.preventDefault();
                // this.deleteTag(tag);
                // for (let i = 0; i < this.wrapper.childNodes.length; i++) {
                //     if (this.wrapper.childNodes[i] == tag)
                //         this.deleteTag(tag, i);
                // }
            // });

            this.wrapper.insertBefore(tag, this.input);
            this.original_input.value = this.arr.join(',');
            return this;
        }

        //ограничения для создания тега
        anyErrors(string) {
            if (this.state.max != null && this.arr.length >= this.state.max) {
                console.log('Max tags limit reached');
                return true;
            }

            if (!this.state.duplicate && this.arr.indexOf(string) != -1) {
                console.log('duplicate found " ' + string + ' " ')
                return true;
            }

            return false;
        }

        //удаление тега
        deleteTag(tag, i) {
            tag.remove();
            this.arr.splice(i, 1);
            this.original_input.value = this.arr.join(',');
            return this;
        }

        //дефолтное заполнение области с тегами
        addData(array) {
            array.forEach(string => {
                this.addTag(string);
            })
            return this;
        }

        // //открытие области для редактирования тега
        // openEditField(tag) {
        //     if (this.isEdit === false) {
        //         const editField = document.createElement('input');
        //         editField.setAttribute('type', 'text');
        //         editField.setAttribute('class', 'tag__field');
        //         console.log(editField)
        //         tag.appendChild(editField)
        //         editField.focus();
        //         this.isEdit = true;
        //     } else {
        //         return;
        //     }
        // }

        // //закрытие области для редактирования тега
        // closeEditField(tag) {
        //     if (this.isEdit === true) {
        //         let editField = tag.querySelector('.tag__field');
        //         editField.remove();
        //     } else {
        //         return;
        //     }
        // }
    }

    const inputs = document.querySelectorAll('.new-mail__input');

    inputs.forEach(input => {
        let parentNode = input.closest('.new-mail__line');
        // console.log(parentNode)

        // const tagInput = 
        new Tags({
            selector: '.new-mail__input',
            duplicate: false,
            max: null,
            parentNode: parentNode,
            wrapperClass: 'new-mail__tags'
        });

        // tagInput.addData(['yfgnng@wmid.com', '678963384824@wmid.com', 'ertertgd@wmid.com']);
    })
}
