export default {
  data(){
    return {
        success: null,
        error: null,
        isLoading: false,
        widthProgressBar: 0,
    }
  },
  methods: {
    handleError(){
      this.error = null;
      this.isLoading = false;
    },
    widthPB(){
      if(this.error === 'canceled'){
        this.error=null
        console.log(':>> canceled');
        return
      }
      const step = 1
      let interval = setInterval(()=>{
          this.widthProgressBar+=step
      },40)
      setTimeout(()=>{
        this.error=null
        clearInterval(interval)
        this.widthProgressBar = 0
        }, 4000)
    },
    widthPB2(){
      const step = 2
      let interval = setInterval(()=>{
          this.widthProgressBar+=step
      },80)
      setTimeout(()=>{
        this.success=null
        clearInterval(interval)
        this.widthProgressBar = 0
        }, 4000)
    },
    errorHandler(err){
      if(err && err.response && err.response.data && err.response.data.error 
        && (err.response.data.error.message || err.response.data.error.meta)){
        if(err.response.data.error.meta && err.response.data.error.meta.code && err.response.data.error.meta.code === 500){
          this.error = this.$t('internal_error')
        }else if(err.response.data.error.meta && err.response.data.error.meta.i8s && this.$t(err.response.data.error.meta.i8s)){
          this.error = this.$t(err.response.data.error.meta.i8s) 
        }
        else if(err.response.data.error.message)
          this.error = err.response.data.error.message 
      }else{
        this.error = err.message || "smth went wrong";
      }
      this.widthPB()
    }
  },
}