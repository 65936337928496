export const updateOrCreateMetaTag = (
  tagName,
  content,
  type = "meta",
  tagNameSelector = "name",
  contentSelector = "content",
  additionalAttributes = []
) => {
  const metaTag = document.querySelector(
    `${type}[${tagNameSelector}="${tagName}"][${contentSelector}]`
  );
  if (metaTag) {
    metaTag.setAttribute(contentSelector, content);
    additionalAttributes.forEach((attribute) => {
      metaTag.setAttribute(attribute.name, attribute.value);
    });
  } else {
    const newMetaTag = document.createElement(type);
    newMetaTag.setAttribute(tagNameSelector, tagName);
    newMetaTag.setAttribute(contentSelector, content);
    additionalAttributes.forEach((attribute) => {
      newMetaTag.setAttribute(attribute.name, attribute.value);
    });
    document.head.appendChild(newMetaTag);
  }
};
